html, body {
  height: 100%;
}

#root {
  height: 100%;
}

/* Remove number input spinners */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
